import * as Uuid from 'uuid';
import { getOsPlatform } from 'src/platform/browser';
import { ProvisionResult } from '../../provisioning/Provisioning';
import { goToPath } from '../../provisioning/utility';

// This matches the regex used on the ULTRA backend
// Saving this regex as it could be used later and matches ULTRA
// const EMAIL_REGEX = /^[a-zA-Z0-9_.+-]{1,100}@[a-zA-Z0-9-]{1,100}\.[a-zA-Z0-9-.]{1,100}$/;

const ACCESS_KEY_REGEX = /^[a-zA-Z0-9_-]{64}$/;

export function validateLoginInput(
  input: string,
): 'access-key' | 'invalid' {
  if (ACCESS_KEY_REGEX.test(input) || Uuid.validate(input)) {
    return 'access-key';
  }

  return 'invalid';
}

export function isLoggedOut(): boolean {
  const urlParams = new URLSearchParams(window.location.search);
  return (urlParams.get('logged-out') === 'true');
}

export function responseParamPresent(): boolean {
  const urlParams = new URLSearchParams(window.location.search);
  return (urlParams.get('response') !== null);
}

export function isPopupWindow() {
  return (
    window.wasOpenedByLandingPage === true ||
    localStorage.getItem('gwa:debug-no-popup') !== null ||
    // We're always in a popup if the address bar isn't visible
    !window.locationbar.visible ||
    // Mobile browsers don't have pop ups in the same way desktops do
    getOsPlatform() === 'ios'
  );
}

export function handleRetryProvisioningResult(res: ProvisionResult) {
  if (
    res.type === 'error' &&
    (res.error.isCredentialsError() || res.error.isBadGatewayError())
  ) {
    // This error after reprovisioning may mean the login token has expired. We
    // should redirect to the login app to get a new one
    goToPath('login/');
  }
}
