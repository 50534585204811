import { addMessages, init, getLocaleFromNavigator } from 'svelte-i18n';
import en_US from 'assets/locale/en-US.json';
import en_GB from 'assets/locale/en-GB.json';

addMessages('en-US', en_US);
addMessages('en-GB', en_GB);

init({
  fallbackLocale: 'en-US',
  initialLocale: getLocaleFromNavigator(),
});
