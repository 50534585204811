import { ProvisioningApiError } from '../../utility/ProvisioningApiError';
import HttpRequest from '../../utility/HttpRequest';
import { makeRequest } from '../../utility/request';
import { getApiUrl } from '../api_stem';
import { ApiError } from '../../utility/ApiError';
import {
  UltraRegion,
  getAllUltraRegions,
  getUltraRegion,
  setUltraRegion,
} from '../../../utility/ultra_region';

export type SamlLoginApiOptions = {
  /**
   * Instead of logging in, just check whether SAML is enabled. This can be used
   * to determine whether to show a "Log in with SSO" button, for example.
   *
   * If SAML is enabled, the response will be `success: true`. If it is not, the
   * response will be `success: false` with a `saml-auth-failed` error.
   */
  checkEnabledOnly?: boolean
};

export const ULTRA_SAML_LOGIN_RESPONSES_SUCCESS = [
  'success-redirect',
] as const;

export type UltraSamlLoginResponseSuccess =
  typeof ULTRA_SAML_LOGIN_RESPONSES_SUCCESS[number];

export type SamlLoginResult =
  {
    success: true,
    type: 'redirect',
    url: string,
  } |
  {
    // ULTRA API errors
    success: false,
    error: ProvisioningApiError,
  };

export async function samlLoginToRegion(
  host: string,
  opts: SamlLoginApiOptions,
  region: UltraRegion,
): Promise<SamlLoginResult> {
  const postBodyObj: {
    check_saml_enabled_only?: boolean,
  } = {};

  if (opts.checkEnabledOnly === true) {
    postBodyObj.check_saml_enabled_only = true;
  }

  let postBody: string | undefined = JSON.stringify(postBodyObj);
  if (postBody === '{}') { postBody = undefined; }

  const req = new HttpRequest({
    url: getApiUrl(host, 'login/saml', region),
    method: 'POST',
    body: postBody,
  });

  const resp = await makeRequest({
    request: req,
    expectedResponseCodes: [200, 302, 400, 401, 403, 500],
  });

  if (!resp.success) {
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: resp.error,
      }),
    };
  }

  if (resp.response.body === undefined) {
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: new ApiError({
          type: 'response-parse-json-error',
          detail: 'No response body',
          request: req,
          response: resp.response,
        }),
      }),
    };
  }

  let parsedResponse: any = {};
  try {
    parsedResponse = JSON.parse(resp.response.body);
  } catch (err) {
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: new ApiError({
          type: 'response-parse-json-error',
          detail: err.message,
          request: req,
          response: resp.response,
        }),
      }),
    };
  }

  // If we're using the "check enabled only" shortcut, the response is
  // different.  I'm just re-purposing the existing return types for this
  // function here, since it's less work. The consumer only really cares about
  // the `success` field. The other fields are populated with dummy values.
  if (opts.checkEnabledOnly === true) {
    if (parsedResponse.saml_enabled === true) {
      setUltraRegion(region);
      return { success: true, type: 'redirect', url: '' };
    }
    return { success: false, error: new ProvisioningApiError({ type: 'saml-auth-failed' })};
  }

  switch (parsedResponse.response) {
    case 'success-redirect':
      setUltraRegion(region);
      return { success: true, type: 'redirect', url: parsedResponse.url };
    case 'saml-auth-failed':
    case 'license-inactive':
    case 'license-expired':
    case 'active-sessions-limit-reached':
    case 'user-deactivated':
    case 'unknown-users-disabled':
      setUltraRegion(region);
      return {
        success: false,
        error: new ProvisioningApiError({ type: parsedResponse.response }),
      };
    case 'bad-request':
    case 'invalid-credentials':
    case 'internal-server-error':
    case 'forbidden':
      return {
        success: false,
        error: new ProvisioningApiError({ type: parsedResponse.response }),
      };
    default:
      return {
        success: false,
        error: new ProvisioningApiError({
          type: 'api-error',
          error: new ApiError({
            type: 'response-validate-json-error',
            detail: 'Failed to validate JSON response',
            request: req,
            response: resp.response,
          }),
        }),
      };
  }
}

export async function samlLogin(host: string, opts: SamlLoginApiOptions): Promise<SamlLoginResult> {
  const ultraRegion = getUltraRegion();

  if (ultraRegion !== null) {
    return samlLoginToRegion(host, opts, ultraRegion);
  }

  // There is no ULTRA region set in local storage, so try logging into each ULTRA region until we
  // get the right one (or we run out of regions).

  // This will always be overwritten as getAllUltraRegions() >= 1, but safer to have a default
  let res: SamlLoginResult = {
    success: false,
    error: new ProvisioningApiError({ type: 'bad-request' }),
  };

  const allUltraRegions = getAllUltraRegions();
  for (let i = 0; i < allUltraRegions.length; ++i) {
    res = await samlLoginToRegion(host, opts, allUltraRegions[i]);
    // loginToRegion will set the ULTRA region in local storage if the response is regionful.
    // If this happens we can return early as we've found our region.
    if (getUltraRegion() !== null) { break; }
  }

  return res;
}
