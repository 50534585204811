<script lang="ts">
  import ASSET_image_user from 'assets/images/user.svg';
  import Modal from '../modal.svelte';
  import Spinner from '../spinner.svelte';

  const title = 'Sign in to Garrison';
</script>

<Modal {title} image={ASSET_image_user} type="information">
  <Spinner size="large" />
  <p class="important">You are now being redirected to your identity provider...</p>
</Modal>

<style lang="scss">

  p.important {
    font-weight: 600;
  }
</style>
