import { ProvisioningApiError } from '../../utility/ProvisioningApiError';
import HttpRequest from '../../utility/HttpRequest';
import { makeRequest } from '../../utility/request';
import { getApiUrl } from '../api_stem';
import { ApiError } from '../../utility/ApiError';
import {
  UltraRegion,
  getAllUltraRegions,
  getUltraRegion,
  setUltraRegion,
} from '../../../utility/ultra_region';
import { LOGGERS } from '../../../utility/log';

const log = LOGGERS['provisioning'];

export type LoginApiOptions =
  {
    userId?: string,
    credentials?: { accessKey: string },
  };

export const ULTRA_LOGIN_RESPONSES_SUCCESS = [
  'success-provision',
] as const;

export type UltraLoginResponseSuccess =
  typeof ULTRA_LOGIN_RESPONSES_SUCCESS[number];

export type LoginResult =
  {
    success: true,
    type: 'provision',
  } |
  {
    // ULTRA API errors
    success: false,
    error: ProvisioningApiError,
  };

async function loginToRegion(
  host: string,
  postBody: string | undefined,
  region: UltraRegion
): Promise<LoginResult> {
  const req = new HttpRequest({
    url: getApiUrl(host, 'login/access-key', region),
    method: 'POST',
    body: postBody,
  });

  const resp = await makeRequest({
    request: req,
    expectedResponseCodes: [200, 302, 400, 401, 403, 500],
  });

  if (!resp.success) {
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: resp.error,
      }),
    };
  }

  if (resp.response.body === undefined) {
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: new ApiError({
          type: 'response-parse-json-error',
          detail: 'No response body',
          request: req,
          response: resp.response,
        }),
      }),
    };
  }

  let parsedResponse: any = {};
  try {
    parsedResponse = JSON.parse(resp.response.body);
  } catch (err) {
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: new ApiError({
          type: 'response-parse-json-error',
          detail: err.message,
          request: req,
          response: resp.response,
        }),
      }),
    };
  }

  switch (parsedResponse.response) {
    case 'success-provision':
      log.info(`Login successful - Setting global ULTRA region to ${region}`);
      setUltraRegion(region);
      return { success: true, type: 'provision' };
    case 'saml-auth-failed':
    case 'license-inactive':
    case 'license-expired':
    case 'user-deactivated':
    case 'unknown-users-disabled':
    case 'active-sessions-limit-reached':
      log.info(`Login failed but region is known - Setting global ULTRA region to ${region}`);
      setUltraRegion(region);
      return {
        success: false,
        error: new ProvisioningApiError({ type: parsedResponse.response }),
      };
    case 'bad-request':
    case 'invalid-credentials':
    case 'forbidden':
    case 'internal-server-error':
      return {
        success: false,
        error: new ProvisioningApiError({ type: parsedResponse.response }),
      };
    default:
      return {
        success: false,
        error: new ProvisioningApiError({
          type: 'api-error',
          error: new ApiError({
            type: 'response-validate-json-error',
            detail: 'Failed to validate JSON response',
            request: req,
            response: resp.response,
          }),
        }),
      };
  }
}

export async function login(host: string, opts: LoginApiOptions): Promise<LoginResult> {
  const postBodyObj: {
    user?: string,
    access_key?: string,
  } = {};

  if (opts.credentials?.accessKey !== undefined) {
    postBodyObj.access_key = opts.credentials.accessKey;
  }

  if (opts.userId !== undefined) {
    postBodyObj.user = opts.userId;
  }

  let postBody: string | undefined = JSON.stringify(postBodyObj);
  if (postBody === '{}') { postBody = undefined; }

  const ultraRegion = getUltraRegion();

  if (ultraRegion !== null) {
    log.info(`Found global ULTRA region: ${ultraRegion}`);
    return loginToRegion(host, postBody, ultraRegion);
  }

  // There is no ULTRA region set in local storage, so try logging into each ULTRA region until we
  // get the right one (or we run out of regions).

  // This will always be overwritten as getAllUltraRegions() >= 1, but safer to have a default
  let res: LoginResult = {
    success: false,
    error: new ProvisioningApiError({ type: 'bad-request' }),
  };

  log.info('No global ULTRA region. Trying all regions.');
  const allUltraRegions = getAllUltraRegions();
  for (let i = 0; i < allUltraRegions.length; ++i) {
    res = await loginToRegion(host, postBody, allUltraRegions[i]);
    // loginToRegion will set the ULTRA region in local storage if the response is regionful.
    // If this happens we can return early as we've found our region.
    if (getUltraRegion() !== null) { break; }
  }

  return res;
}
