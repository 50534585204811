type LogFunction = (...args: any[]) => void;

export type Loggers = {
  debug:   LogFunction,
  log:     LogFunction,
  info:    LogFunction,
  warn:    LogFunction,
  error:   LogFunction,
};

function createLoggers(
  prefix: string,
  prefixColor: string,
): Loggers {
  const a = `%c[${prefix}]`;
  const b = `background: ${prefixColor}; color: rgba(0, 0, 0, 0.6)`;

  /* eslint-disable no-console */
  return {
    debug: console.debug.bind(window.console, a, b),
    log: console.log.bind(window.console, a, b),
    info: console.info.bind(window.console, a, b),
    warn: console.warn.bind(window.console, a, b),
    error: console.error.bind(window.console, a, b),
  };
  /* eslint-enable */
}

export const LOGGERS = {
  migration: createLoggers('Session migration', '#96e693'),
  provisioning: createLoggers('Provisioning', '#d3ffd1'),
  transfer: createLoggers('Transfer', '#b3edff'),
  video: createLoggers('Video', '#ffd1d1'),
  ccp: createLoggers('CCP', '#ffd1f7'),
  network: createLoggers('Network', '#fff3d1'),
  webRtc: createLoggers('WebRTC', '#f5de9d'),
  application: createLoggers('Application', '#a6cfe0'),
  audio: createLoggers('Audio', '#ffce8f'),
  browser: createLoggers('Browser', '#c7cdff'),
  fileTransfer: createLoggers('FileTransfer', '#d5a2f2'),
  keyboardManager: createLoggers('Keyboardmanager', '#e2f2a2'),
  diagnostics: createLoggers('Diagnostics', '#fa69b4'),
  development: createLoggers('Development', '#bada55'),
  vtc: createLoggers('VTC', '#ffce8f')
};
