export const PAGE_LOAD_TIME = Date.now();

export const OS_PLATFORMS = ['windows', 'mac', 'linux', 'ios', 'unknown'] as const;
export type OsPlatform = typeof OS_PLATFORMS[number];

export function getOsPlatform(): OsPlatform {
  // Deprecated but well-supported
  const platformString = window.navigator.platform;

  if (platformString.startsWith('Win')) {
    return 'windows';
  }

  // iOS detection taken from https://stackoverflow.com/a/9039885
  // Must be placed above the 'mac' detection.
  if (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)) {
    return 'ios';
  }

  if (platformString.startsWith('Mac')) {
    return 'mac';
  }

  if (platformString.startsWith('Linux')) {
    return 'linux';
  }

  return 'unknown';
}

export type BrowserClient = 'safari' | 'unknown';

export function getBrowserClient(): BrowserClient {
  // Taken (roughly) from
  // https://stackoverflow.com/a/12625944
  //
  // add more as needed.
  // Note that all web browsers on ios will use WebKit under the hood
  // so the browser client will also be 'safari' for them.
  const safari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

  if (safari) {
    return 'safari';
  }

  return 'unknown';
}

export function getTimeZone() {
  return new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getTimeZoneOffsetFromUtc() {
  return -new Date().getTimezoneOffset();
}

export function getLocale() {
  const str = window.navigator.language || window.navigator.languages[0];
  return str.toLowerCase();
}

export type WindowDimensions = {
  left: number,
  top: number,
  width: number,
  height: number,
};

export function generateDefaultPopupWindowDimensions(): WindowDimensions {
  return {
    left: 200,
    top: 200,
    width: 1280,
    height: 960,
  };
}

export function getWindowDimensions(): WindowDimensions {
  return {
    top: window.screenTop,
    left: window.screenLeft,
    width: window.outerWidth,
    height: window.outerHeight,
  };
}

export function getDevicePixelRatio() {
  return window.devicePixelRatio;
}

export function getWindowInnerDimensions() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

export function clearUrlPath() {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname.replace(/\?.+$/, ''),
  );
}

export function closeWindow(delay: number = 0) {
  const func = () => {
    window.close();

    // Fallback
    window.history.back();
  };

  if (delay === 0) {
    func();
  } else {
    setTimeout(func, delay);
  }
}

export function downloadFileFromUrl(downloadUrl: string, filename: string) {
  // Works by creating an 'a' element, clicks it which starts download
  // and removes the element
  const element = document.createElement('a');
  element.setAttribute('href', downloadUrl);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
