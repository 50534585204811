<div class="page">
  <slot />
</div>

<style lang="scss">
  div.page {
    width: 100%;
    height: 100%;

    display: grid;
    place-items: center;

    overflow: hidden;
  }
</style>