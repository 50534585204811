import { ApiError } from '../../utility/ApiError';
import { makeRequest } from '../../utility/request';
import HttpRequest from '../../utility/HttpRequest';
import { getTransferUrl } from '../api_stem';

type GetGatewayTokenResult =
  {
    success: true,
    gatewayToken: string,
  } |
  {
    success: false,
    error: ApiError,
  };

export async function getGatewayToken(
  host: string
): Promise<GetGatewayTokenResult> {
  const schema = {
    properties: {
      'gateway_token': { type: 'string' },
    },
  } as const;

  const resp = await makeRequest({
    request: new HttpRequest({
      url: getTransferUrl(host, 'gatewaytoken'),
      method: 'GET',
    }),
    expectedResponseCodes: [200],
    jsonValidationSchema: schema,
  });

  if (!resp.success) {
    return {
      success: false,
      error: resp.error,
    };
  }

  const validatedResult = resp.value;

  return {
    success: true,
    gatewayToken: validatedResult.gateway_token,
  };
}
