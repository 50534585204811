<script lang="ts">
  export let label: string | undefined;
  export let error: string | undefined;
  export let autoFocus: boolean = false;
  export let value: string = '';
  export let disabled: boolean = false;
</script>

<div class="input-controls">
  {#if label !== undefined}
    <span class="label">{label}</span>
  {/if}

  <!-- svelte-ignore a11y-autofocus -->
  <input
    bind:value
    autofocus={autoFocus}
    spellcheck={false}
    autocapitalize="off"
    {disabled}
  />

  {#if error !== undefined}
    <span class="error">{error}</span>
  {/if}
</div>

<style lang="scss">
  @use '../scss/animations.scss';

  .input-controls {
    align-self: stretch;

    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    gap: 4px;
  }

  .label {
    font-weight: 600;
    font-size: 16px;
  }

  .error {
    font-size: 14px;
    color: #dc2626;
    
    @include animations.slide-down;
  }

  input {
    align-self: stretch;

    padding: 0 12px;
    height: 42px;
    border: 2px solid #bed1ce;
    border-radius: 4px;

    font-size: 14px;

    text-align: left;

    transition-property: border-color, opacity;
    transition-duration: 180ms;

    &:not(:disabled) {
      &:focus {
        border-color: #0693e3;
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }
</style>
