import 'svelte';

import './locale/init';

import './ui_new/scss/fonts.scss';
import './ui_new/scss/global.scss';

import '../assets/theme.scss';
import 'the-new-css-reset/css/reset.css';

import LoginPage from './ui_new/pages/login_page.svelte';

/* eslint-disable @typescript-eslint/no-unused-vars */

const main = new LoginPage({
  target: document.body,
});
