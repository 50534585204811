<script lang='ts'>
    export let icon: string;
    export let alt: string;
    export let colorType: 'danger' | 'information' | 'success' | 'warning' | 'modal-danger' | undefined = undefined;
    export let size: 'sm' | 'md' = 'sm';
    export let darkMode: boolean = false;
</script>

<div
    class={`circle-icon size-${size}`} 
    class:dark-mode = {darkMode}
    class:danger = {colorType === 'danger'}
    class:information = {colorType === 'information'}
    class:success= {colorType === 'success'}
    class:warning= {colorType === 'warning'}
    class:modal-danger = {colorType === 'modal-danger'}
>
    <img src={icon} {alt}/>
</div>

<style lang="scss">
    .circle-icon {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: var(--theme-col-pale-blue);
        fill: var(--theme-col-bright-blue);
        display: grid;
        place-items: center;
        user-select: none;

        &.size-md { 
            width: 52px;
            height: 52px;
            border-radius: 30px;
        }

        &.danger {
            background-color: var(--theme-col-purple);
            fill: var(--theme-col-error);
        }

        &.modal-danger {
            background-color: #FFD1D1;
        }

        &.success {
            background-color: var(--theme-col-greenish-teal);
            fill: var(--theme-col-light-green-2);
        }

        &.information {
            background-color: var(--theme-col-pale-blue);
            fill: var(--theme-col-bright-blue);

            &-dark {
                background-color: var(--theme-col-dark-blue);
            }
        }

        &.dark-mode {
            &.information {
                background-color: var(--theme-col-dark-blue);
            }
            &.success {
                background-color:  var(--theme-col-greenish-teal);
            }
        }

        &.warning  {
            background-color: var(--theme-col-magenta);
            fill: var(--theme-col-light-brown-1);
        }

        img {
            width: 20px;
            height: 20px;
        }
    }
</style>
