import { MessageFormatter } from 'svelte-i18n/types/runtime/types';
import { ApiError } from '../../utility/ApiError';
import HttpRequest from '../../utility/HttpRequest';
import { makeRequest } from '../../utility/request';
import Error from '../../../utility/Error';
import { getApiUrl } from '../api_stem';

type RenewSessionOptions = {
  /**
   * Time in seconds since the user last interacted with the session.
   */
  timeSinceLastInteraction: number,

  /**
   * Is the user considered active?
   */
  active: boolean,
};

type RenewSessionErrorDetail =
{
  type:
  'session-limit-reached' |
  'license-expired' |
  'forbidden',
} |
{
  type: 'api-error',
  error: ApiError,
};

export class RenewSessionError implements Error<RenewSessionErrorDetail> {
  constructor(
    readonly detail: RenewSessionErrorDetail,
  ) {}

  dump(): string {
    return JSON.stringify(this.detail);
  }

  toLocalizedString(_formatter: MessageFormatter): string | null {
    return null;
  }

  getDetailString(): string | null {
    switch (this.detail.type) {
      case 'api-error':
        return this.detail.error.getDetailString();
    }

    return null;
  }
}

type RenewSessionResult =
{
  success: true,
  sessionId: string,
} |
{
  success: false,
  error: RenewSessionError,
};

export async function renewSession(
  host: string,
  opts: RenewSessionOptions,
): Promise<RenewSessionResult> {
  const putBodyJson = {
    last_interaction: opts.timeSinceLastInteraction,
    active: opts.active,
  };

  const req = new HttpRequest({
    url: getApiUrl(host, 'session'),
    method: 'PUT',
    body: JSON.stringify(putBodyJson),
  });

  const resp = await makeRequest({
    request: req,
    expectedResponseCodes: [200, 402, 403],
  });

  if (!resp.success) {
    return {
      success: false,
      error: new RenewSessionError({
        type: 'api-error',
        error: resp.error,
      }),
    };
  }

  if (resp.response.body === undefined) {
    return {
      success: false,
      error: new RenewSessionError({
        type: 'api-error',
        error: new ApiError({
          type: 'response-parse-json-error',
          detail: 'No response body',
          request: req,
          response: resp.response,
        }),
      }),
    };
  }

  // Renew session success, extract session id
  if (resp.response.statusCode === 200) {
    let parsedResponse: any = {};
    try {
      parsedResponse = JSON.parse(resp.response.body);
    } catch (err) {
      return {
        success: false,
        error: new RenewSessionError({
          type: 'api-error',
          error: new ApiError({
            type: 'response-parse-json-error',
            detail: err.message,
            request: req,
            response: resp.response,
          }),
        }),
      };
    }

    return {
      success: true,
      sessionId: parsedResponse.session_id,
    };
  }

  // These cases would cause the app to disconnect
  // If there is another reason the session will continue
  const reason = resp.response.body;
  switch (reason) {
    case 'session-limit-reached':
      return {
        success: false,
        error: new RenewSessionError({
          type: 'session-limit-reached',
        }),
      };
    case 'license-expired':
      return {
        success: false,
        error: new RenewSessionError({
          type: 'license-expired',
        }),
      };
    case 'forbidden':
      return {
        success: false,
        error: new RenewSessionError({
          type: 'forbidden',
        }),
      };
    default:
      return {
        success: false,
        error: new RenewSessionError({
          type: 'api-error',
          error: new ApiError({
            type: 'response-validate-json-error',
            detail: 'Failed to validate response',
            request: req,
            response: resp.response,
          }),
        }),
      };
  }
}
