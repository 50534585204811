import { MessageFormatter } from 'svelte-i18n/types/runtime/types';
import Error from '../../utility/Error';
import { ApiError } from './ApiError';

const ULTRA_PROVISIONING_RESPONSES_ERROR = [
  'bad-request',
  'invalid-credentials',
  'saml-auth-failed',
  'license-inactive',
  'license-expired',
  'missing-login-token',
  'active-sessions-limit-reached',
  'user-deactivated',
  'unknown-users-disabled',
  'internal-server-error',
] as const;

export type UltraProvisioningResponseError =
  typeof ULTRA_PROVISIONING_RESPONSES_ERROR[number];

type ProvisioningApiErrorDetail =
{
  type: UltraProvisioningResponseError,
} |
{
  type: 'api-error',
  error: ApiError,
};

export class ProvisioningApiError implements Error<ProvisioningApiErrorDetail> {
  constructor(
    readonly detail: ProvisioningApiErrorDetail,
  ) {}

  dump(): string {
    return JSON.stringify(this.detail);
  }

  toLocalizedString(formatter: MessageFormatter): string | null {
    let str: string | null = null;

    switch (this.detail.type) {
      case 'active-sessions-limit-reached':
        str = formatter('login_page.error.connecting_error_active_sessions_limit_reached');
        break;
      case 'invalid-credentials':
        str = formatter('login_page.error.connecting_error_invalid_credentials');
        break;
      case 'saml-auth-failed':
        str = formatter('login_page.error.connecting_error_saml_auth_failed');
        break;
      case 'license-expired':
        str = formatter('login_page.error.connecting_error_license_expired');
        break;
      case 'license-inactive':
        str = formatter('login_page.error.connecting_error_license_inactive');
        break;
      case 'user-deactivated':
        str = formatter('login_page.error.connecting_error_user_deactivated');
        break;
      case 'unknown-users-disabled':
        str = formatter('login_page.error.connecting_error_unknown_users_disabled');
        break;
      case 'api-error':
        str = this.detail.error.toLocalizedString(formatter);
        break;
    }

    return str;
  }

  getDetailString(): string | null {
    switch (this.detail.type) {
      case 'api-error':
        return this.detail.error.getDetailString();
      case 'bad-request':
        return 'Bad request';
      case 'internal-server-error':
        return 'Internal server error';
    }

    return null;
  }
}
