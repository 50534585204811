type HttpHeaders = { [key: string]: string };

type HttpResponseInit = {
  statusCode: number;
  headers?: HttpHeaders;
  body?: string;
};

export default class HttpResponse {
  readonly statusCode: number;
  readonly headers: HttpHeaders;
  readonly body?: string;

  constructor(opts: HttpResponseInit) {
    this.statusCode = opts.statusCode;
    this.headers = opts.headers ?? {};
    this.body = opts.body;
  }

  // Note:
  // Doing weird static create because the constructor needs to be asynchronous.
  // (Reading the body from a fetch response returns a Promise...)

  static async fromFetchResponse(
    fetchResponse: Response,
  ): Promise<HttpResponse> {
    let body: string | undefined;

    const headers: HttpHeaders = {};
    fetchResponse.headers.forEach((value, key) => {
      headers[key] = value;
    });

    try {
      body = await fetchResponse.text();
    } catch {
      // Do nothing
    }

    if (body !== undefined && body.length === 0) {
      body = undefined;
    }

    return new HttpResponse({
      statusCode: fetchResponse.status,
      headers,
      body,
    });
  }

  dump(): string {
    let str = '';

    str += `Code: ${this.statusCode}\n`;

    str += `Headers:\n\n${JSON.stringify(this.headers, null, 2)}\n`;

    if (this.body === undefined) {
      str += 'Body: <empty>';
    } else {
      str += `Body:\n\n${this.body.slice(0, 4096)}`;
    }

    return str;
  }
}
