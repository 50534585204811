import Ajv, { JTDDataType } from 'ajv/dist/jtd';
import { Result } from './result';

const ajv = new Ajv();

/**
 * Validates that a JSON object fits a JTD schema
 * @param json Json object
 * @param schema JTD schema
 * @returns Validation result. On success, value is the original json object
 *          coerced to a type matching the schema.
 */
export function validateJson<T>(json: any, schema: T): Result<JTDDataType<T>, string> {
  const validate = ajv.compile<JTDDataType<T>>(schema);
  const valid = validate(json);

  if (!valid) {
    return {
      success: false,
      error: JSON.stringify(validate.errors, null, 2) ?? '',
    };
  }

  return {
    success: true,
    value: json,
  };
}

export function isObject(object: any): object is { [key: string]: any } {
  return (
    !Array.isArray(object) &&
    typeof object === 'object' &&
    object !== null
  );
}

export function isStringArray(object: any): object is string[] {
  if (!Array.isArray(object)) return false;

  for (let i = 0; i < object.length; ++i) {
    const element = object[i];
    if (typeof element !== 'string') {
      return false;
    }
  }

  return true;
}
