export function parseCookies(): { [key: string]: string } {
  const cookies: { [key: string]: string } = {};

  document.cookie.split(';').forEach(cookie => {
    const values = cookie.split('=');
    if (values.length < 2) return;

    const name = values[0].trim();
    const value = values.slice(1).join('=').trim();

    cookies[name] = decodeURIComponent(value);
  });

  return cookies;
}

export function getCookie(name: string): string | null {
  return document.cookie
    .split('; ')
    .find(row => row.startsWith(`${name}=`))
    ?.split('=')[1] ?? null;
}

export function deleteCookie(name: string) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
