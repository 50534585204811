import { MessageFormatter } from 'svelte-i18n/types/runtime/types';
import Error from '../../utility/Error';
import HttpResponse from './HttpResponse';
import HttpRequest from './HttpRequest';

export type ApiErrorDetail =
{ request: HttpRequest } &
(
  {
    type: 'network-error',
    detail: string,
  } |
  (
    { response: HttpResponse } &
    (
      {
        type:
        'response-parse-json-error' |
        'response-validate-json-error',

        detail: string,
      } |
      {
        type: 'unexpected-response-code',
        expectedResponseCodes: number[],
      }
    )
  )
);

type ApiErrorType = ApiErrorDetail['type'];

function apiErrorTypeToString(type: ApiErrorType): string {
  let str: string;

  switch (type) {
    case 'network-error':
      str = 'A network error occurred.';
      break;
    case 'response-parse-json-error':
      str = 'Failed to parse response JSON.';
      break;
    case 'response-validate-json-error':
      str = 'Failed to validate response JSON.';
      break;
    case 'unexpected-response-code':
      str = 'Unexpected response code.';
      break;
  }

  return str;
}

export class ApiError implements Error<ApiErrorDetail> {
  constructor(
    readonly detail: ApiErrorDetail,
  ) {}

  dump(): string {
    return JSON.stringify(this.detail);
  }

  toLocalizedString(formatter: MessageFormatter): string | null {
    switch (this.detail.type) {
      case 'network-error':
        return formatter('error.api_error.network_error');
      case 'response-parse-json-error':
        return formatter('error.api_error.response_parse_json_error');
      case 'response-validate-json-error':
        return formatter('error.api_error.response_validate_json_error');
      case 'unexpected-response-code':
        return formatter('error.api_error.unexpected_response_code', {
          values: {
            'received': this.detail.response.statusCode,
          },
        });
      default:
        return null;
    }
  }

  getDetailString(): string | null {
    let str = '';

    str += apiErrorTypeToString(this.detail.type);

    if ('detail' in this.detail) {
      str += `\n\n${this.detail.detail}`;
    }

    str += '\n\n****************\n\n';

    str += 'Request:\n\n';
    str += this.detail.request.dump();
    str += '\n\n';
    str += '****************';

    if ('response' in this.detail) {
      str += '\n\nResponse:\n\n';
      str += this.detail.response.dump();
      str += '\n\n';
      str += '****************';
    }

    return str;
  }

  isUnexpectedResponseCode(code: number): boolean {
    return (
      this.detail.type === 'unexpected-response-code' &&
      this.detail.response.statusCode === code
    );
  }
}
