import HttpRequest from '../../utility/HttpRequest';
import { makeRequest } from '../../utility/request';
import { getApiUrl } from '../api_stem';

export async function saviNodesDebug(
  host: string,
  opts: { offer: string },
): Promise<string> {
  const postBodyJson = {
    offer: opts.offer,
  };

  const res = await makeRequest({
    request: new HttpRequest({
      url: getApiUrl(host, 'savi-nodes-debug'),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postBodyJson),
    }),
    expectedResponseCodes: [200],
  });

  if (res.success) {
    const p = JSON.parse(res.response.body!);
    return p.answer;
  }

  return '';
}
