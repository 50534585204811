import { FileScanningIntegrationServiceType } from 'src/file_transfer/FileTransfer';
import { makeRequest } from '../../utility/request';
import HttpRequest from '../../utility/HttpRequest';
import { getApiUrl } from '../api_stem';
import { ProvisioningApiError } from '../../utility/ProvisioningApiError';
import { ApiError } from '../../utility/ApiError';

export type SaviNodesOptions =
{
  systemKey: string,
  boardId?: number,
  nodeId?: number,
  supportedNpvs: readonly number[],
  userSequence?: number,
  sessionConfig: {
    resize: {
      width: number,
      height: number,
      density: number,
    },
    url_redirect: boolean,
    gateway_uuid: string,
    device: string,
    locale: string,
    timezone: string,
  },
  zeroPersistence?: boolean,
  offer:
  {
    type: 'ice',
    iceUfrag: string,
    icePwd: string,
    fingerprint: string,
    mid: string,
  } |
  {
    type: 'garrison',
    encryption:
    {
      enabled: false,
    } |
    {
      enabled: true,
      appPublicKey: string,
    }
  },
};

type SaviNodesSuccessResponse =
{
  ipAddress: string,
  protocolPort: number,
  boardId: number,
  nodeId: number,
  npv: number,
  answer:
  {
    type: 'ice',
    sessionId: number,
    candidateId: number,
    fingerprint: string,
    iceUfrag: string,
    icePwd: string,
    mid: string,
  },
  // {
  //   type: 'garrison',
  //   encryption: {
  //     enabled: false,
  //   } |
  //   {
  //     enabled: true,
  //     giaPublicKey: string
  //   }
  // },
  grayscaleCorrectionRequired: boolean,
  highToLowPasteAllowed?: boolean,
  inactivityTimeoutDefaultDurationSec?: number,
  inactivityTimeoutExtensionDurationSec?: number,
  debugLogs?: boolean,
  newUi?: boolean,  // NOTE: leave as might be returned from api, don't use
  fileTransferEnabled?: boolean,
  fileTransferIntegrationService?: FileScanningIntegrationServiceType | null
};

export type MessageResponse = {
  messageId: string,
  messageTitle: string,
  messageText: string,
  acceptButtonText: string,
  declineButtonText: string,
};

export type ProvisionSaviNodeResult =
{
  result: 'success',
  response: SaviNodesSuccessResponse,
} |
{
  result: 'message',
  response: MessageResponse,
} |
{
  result: 'error',
  error: ProvisioningApiError,
};

export async function saviNodes(
  host: string,
  opts: SaviNodesOptions,
): Promise<ProvisionSaviNodeResult> {
  // Convert options into post body
  const supportedNpvs = opts.supportedNpvs.map(npv => npv * 1000);

  let postBodyJson: any = {
    user_sequence: 0,
    system_key: opts.systemKey,
    ...(opts.boardId !== undefined && { board_id: opts.boardId }),
    ...(opts.nodeId !== undefined && { node_id: opts.nodeId }),
    supported_npvs: supportedNpvs,
    ...(opts.userSequence !== undefined && { user_sequence: opts.userSequence }),
    session_config: opts.sessionConfig,
    zero_persistence: opts.zeroPersistence,
  };

  if (opts.offer.type === 'ice') {
    postBodyJson = {
      ...postBodyJson,
      ice_offer: {
        ice_ufrag: opts.offer.iceUfrag,
        ice_pwd: opts.offer.icePwd,
        fingerprint: opts.offer.fingerprint,
        mid: opts.offer.mid,
      },
    };
  } else if (opts.offer.type === 'garrison') {
    postBodyJson = {
      ...postBodyJson,
      gar_offer: {
        enabled: opts.offer.encryption.enabled,
        ...(opts.offer.encryption.enabled && { public_key: opts.offer.encryption.appPublicKey }),
      },
    };
  }

  const req = new HttpRequest({
    url: getApiUrl(host, 'savi-nodes'),
    method: 'POST',
    body: JSON.stringify(postBodyJson),
  });

  const resp = await makeRequest({
    request: req,
    expectedResponseCodes: [200, 400, 401, 403, 500],
  });

  if (!resp.success) {
    return {
      result: 'error',
      error: new ProvisioningApiError({
        type: 'api-error',
        error: resp.error,
      }),
    };
  }

  if (resp.response.body === undefined) {
    return {
      result: 'error',
      error: new ProvisioningApiError({
        type: 'api-error',
        error: new ApiError({
          type: 'response-parse-json-error',
          detail: 'No response body',
          request: req,
          response: resp.response,
        }),
      }),
    };
  }

  let parsedResponse: any = {};
  try {
    parsedResponse = JSON.parse(resp.response.body);
  } catch (err) {
    return {
      result: 'error',
      error: new ProvisioningApiError({
        type: 'api-error',
        error: new ApiError({
          type: 'response-parse-json-error',
          detail: err.message,
          request: req,
          response: resp.response,
        }),
      }),
    };
  }

  switch (parsedResponse.response) {
    case undefined:
      try {
        return {
          result: 'success',
          response: {
            ipAddress: parsedResponse.ip_address,
            boardId: parsedResponse.board_id,
            nodeId: parsedResponse.node_id,
            npv: parsedResponse.npv,
            protocolPort: parsedResponse.protocol_port,
            answer: {
              type: 'ice',
              fingerprint: parsedResponse.ice_answer.fingerprint,
              icePwd: parsedResponse.ice_answer.ice_pwd,
              iceUfrag: parsedResponse.ice_answer.ice_ufrag,
              mid: parsedResponse.ice_answer.mid,
              sessionId: parsedResponse.ice_answer.session_id,
              candidateId: parsedResponse.ice_answer.candidate_id,
            },
            grayscaleCorrectionRequired: parsedResponse.grayscale_correction_required,
            highToLowPasteAllowed: parsedResponse.high_to_low_paste_allowed,
            inactivityTimeoutDefaultDurationSec: parsedResponse.inactivity_timeout_default_duration_sec,
            inactivityTimeoutExtensionDurationSec: parsedResponse.inactivity_timeout_extension_duration_sec,
            debugLogs: parsedResponse.debug_logs,
            fileTransferEnabled: parsedResponse.file_transfer_enabled,
            fileTransferIntegrationService: parsedResponse.file_transfer_integration_service
          },
        };
      } catch (err) {
        return {
          result: 'error',
          error: new ProvisioningApiError({
            type: 'api-error',
            error: new ApiError({
              type: 'response-validate-json-error',
              detail: err.message,
              request: req,
              response: resp.response,
            }),
          }),
        };
      }
    case 'bad-request':
    case 'invalid-credentials':
    case 'license-inactive':
    case 'license-expired':
    case 'missing-login-token':
    case 'active-sessions-limit-reached':
    case 'internal-server-error':
    case 'forbidden':
      return {
        result: 'error',
        error: new ProvisioningApiError({ type: parsedResponse.response }),
      };
    case 'splash-message':
      return {
        result: 'message',
        response: {
          messageId: parsedResponse.message_id,
          messageTitle: parsedResponse.message_title,
          messageText: parsedResponse.message_text,
          acceptButtonText: parsedResponse.accept_button_text,
          declineButtonText: parsedResponse.decline_button_text,
        }
      };
    default:
      return {
        result: 'error',
        error: new ProvisioningApiError({
          type: 'api-error',
          error: new ApiError({
            type: 'response-validate-json-error',
            detail: 'Failed to validate JSON response',
            request: req,
            response: resp.response,
          }),
        }),
      };
  }
}
