<script lang="ts">
  import ASSET_image_user from 'assets/images/user.svg';
  import Modal from '../modal.svelte';
  
  const title = 'Successfully signed in to Garrison!';
</script>

<Modal {title} image={ASSET_image_user} type="information">
  <p class="desc">Your sign in was successful. You may now close this page.</p>
</Modal>

<style lang="scss">
  p.desc {
    color: #636363;
  }
</style>
