type HttpRequestInit = {
  url: string,
  method: string,
  body?: string,
  credentials?: RequestCredentials,
  headers?: HeadersInit,
};

export default class HttpRequest {
  readonly url: string;
  readonly method: string;
  readonly body?: string;
  readonly credentials?: RequestCredentials;
  readonly headers?: HeadersInit;

  constructor(opts: HttpRequestInit) {
    this.url = opts.url;
    this.method = opts.method;
    this.body = opts.body;
    this.credentials = opts.credentials;
    this.headers = opts.headers;
  }

  dump(): string {
    let str = '';

    str += `URL: ${this.url}\n`;
    str += `Method: ${this.method}\n`;

    if (this.body === undefined) {
      str += 'Body: <empty>';
    } else {
      str += `Body:\n\n${this.body}`;
    }

    return str;
  }
}
