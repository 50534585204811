<script lang="ts">
  import ASSET_image_user from 'assets/images/user.svg';
  import { createEventDispatcher } from 'svelte';
  import Modal from '../modal.svelte';
  import Button from '../button.svelte';

  const dispatch = createEventDispatcher<{
    'continue-sso': void,
    'continue-access-key': void,
  }>();

  export let showSsoButton: boolean;
  export let disableButtons: boolean;

  const title = 'Sign in to Garrison';
</script>

<Modal {title} image={ASSET_image_user} type="information">
  <p class="desc">Please sign in to continue securely browsing the internet.</p>

  <svelte:fragment slot="footer">
      {#if showSsoButton}
        <Button
          on:press={() => { dispatch('continue-sso'); }}
          disabled={disableButtons}
        >
          Continue with SSO
        </Button>
      {/if}
      <Button
        kind={showSsoButton ? 'secondary' : 'primary'}
        on:press={() => { dispatch('continue-access-key'); }}
        disabled={disableButtons}
      >
        Continue with an access key
      </Button>
  </svelte:fragment>
</Modal>

<style lang="scss">
  p.desc {
    color: #636363;
  }
</style>
