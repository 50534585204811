<script lang="ts">
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import ASSET_image_bg from 'assets/images/bg_login.webp';
  import Page from '../components/page.svelte';
  import PoweredByGarrison from '../components/powered_by_garrison.svelte';
  import ModalSignInRoot from '../components/modals/modal_sign_in_root.svelte';
  import ModalSignInSso from '../components/modals/modal_sign_in_sso.svelte';
  import ModalSignInAccessKey from '../components/modals/modal_sign_in_access_key.svelte';
  import ModalSignInSuccess from '../components/modals/modal_sign_in_success.svelte';
  import * as Gcb from '../../api_services/gcb';
  import Globals from '../../Globals';
  import { ExtrinsicPromise } from '../../utility';
  import Profile from '../../profile';
  import { validateLoginInput } from '../../ui/apps/utility';
  import ModalSignInError from '../components/modals/modal_sign_in_error.svelte';
  import { goToPath } from '../../provisioning/utility';
  import { LoginExtendedResult, validateUserAuthDetails } from '../../api_services/gcb/endpoints/login_extended';
  import { LoginResult } from '../../api_services/gcb/endpoints/login';
  import ModalSignInLoading from '../components/modals/modal_sign_in_loading.svelte';

  // -------------
  // State
  // -------------

  type State =
    | { step: 'root' }
    | { step: 'access-key', initialKey?: string, error?: string }
    | { step: 'sso' }
    | { step: 'success' }
    | { step: 'error', error: string };

  let state: State = { step: 'root' };

  let disableButtons = false;

  // This state starts as undefined. We make an API call `onMount` (see bottom)
  // to determine whether SSO is enabled.
  let ssoEnabled: boolean | undefined;

  // ----------------
  // Helper functions
  // ----------------

  function cacheSearchParams() {
    window.localStorage.setItem('gwa:login-search', window.location.search);
  }

  function restoreCachedSearchParams() {
    const c = window.localStorage.getItem('gwa:login-search');

    if (c === null) {
      return;
    }

    window.localStorage.removeItem('gwa:login-search');

    const searchParams = new URLSearchParams(c);

    const url = new URL(window.location.href);

    searchParams.forEach((value, key) => {
      url.searchParams.set(key, value);
    });

    window.history.replaceState(null, '', url.toString());
  }

  function formatLoginError(error: string): string {
    switch (error) {
      case 'invalid-credentials':
        return 'Invalid credentials';
      case 'bad-request':
        return 'Bad request';
      case 'saml-auth-failed':
        return 'Invalid SAML response from your identity provider. Please contact your administrator.';
      case 'license-inactive':
        return 'License inactive. Please contact your administrator.';
      case 'license-expired':
        return 'License expired. Please contact your administrator.';
      case 'user-deactivated':
        return 'Your user account is disabled. Please contact your administrator.';
      case 'unknown-users-disabled':
        return 'Your account is not in the organization\'s known users list. Please contact your organization administrator.';
      default: return 'An unexpected error occurred.';
    }
  }

  const query = (() => {
    let q = new URLSearchParams(document.location.search);
    const response = q.get('response');

    if (response === 'success-provision') {
      restoreCachedSearchParams();
      q = new URLSearchParams(document.location.search);
    }

    const mode = q.get('mode');
    const userId = q.get('user-id');
    const k = q.get('k');
    const userAuthDetails = q.get('u');
    const url = q.get('url');

    return {
      response,
      mode,
      userId,
      k,
      userAuthDetails,
      url
    };
  })();

  const userId = new Profile({ userId: query.userId ?? undefined }).userId;

  function handleSuccess() {
    state = { step: 'success' };

    // This event is dispatched to notify the Garrison extension that login has
    // completed.
    document.dispatchEvent(new CustomEvent('garrison-login-complete'));

    if (query.mode !== 'extension' && query.mode !== 'browser') {
      // Redirect to the In-browser App (maintaining all query parameters)
      goToPath('');
    }
  }

  function performSsoRedirect(url: string) {
    // This event is dispatched to notify the Garrison extension that SSO is
    // about to occur.
    document.dispatchEvent(new CustomEvent('garrison-login-sso-redirect'));
    cacheSearchParams();

    window.location.replace(url);
  }

  async function handleAutomaticOnPremLogin() {
    const res = await Gcb.onPremLogin(Globals.loginApiHost, {
      userId,
    });

    if (res.success) {
      switch (res.type) {
        case 'provision': {
          handleSuccess();
          break;
        }

        case 'redirect': {
          state = { step: 'sso' };
          performSsoRedirect(res.url);
          break;
        }
      }
    }
  }

  async function handlePressAccessKeySignIn(opts: { accessKey: string, res: ExtrinsicPromise<void> }) {
    if (state.step !== 'access-key') {
      return;
    }

    state.error = undefined;

    if (validateLoginInput(opts.accessKey) === 'invalid') {
      state.error = 'This is not a valid access key.';
      opts.res.resolve();
      return;
    }

    let res: LoginExtendedResult | LoginResult;

    if (query.userAuthDetails !== null) {
      // Special user auth details endpoint ("extended login")

      const userAuthDetails = validateUserAuthDetails(query.userAuthDetails);

      if (userAuthDetails === undefined) {
        state = {
          step: 'error',
          error: 'The user authentication details provided are in the wrong format. Please contact your organization administrator.',
        };

        return;
      }

      res = await Gcb.loginExtended(Globals.loginApiHost, {
        userId,
        credentials: { accessKey: opts.accessKey },
        userAuthDetails,
      });
    } else {
      res = await Gcb.login(Globals.loginApiHost, {
        userId,
        credentials: { accessKey: opts.accessKey },
      });
    }

    if (res.success) {
      handleSuccess();
    } else {
      state.error = res.error.toLocalizedString($_) ?? undefined;
    }

    opts.res.resolve();
  }

  async function handleContinueAccessKey() {
    disableButtons = true;
    const res = await Gcb.login(Globals.loginApiHost, {});
    if (res.success) {
      handleSuccess();
    } else {
      state = { step: 'access-key' };
    }
    disableButtons = false;
  }

  async function handlePressRootContinueSso() {
    state = { step: 'sso' };

    const res = await Gcb.samlLogin(Globals.loginApiHost, {});

    if (res.success) {
      performSsoRedirect(res.url);
    }
  }

  async function handlePressErrorGoBack() {
    const params = new URLSearchParams(window.location.search);
    params.delete('response');

    const newQueryString = params.toString();
    const newUrl = `${window.location.origin}${window.location.pathname}?${newQueryString}`;

    window.history.replaceState({}, '', newUrl);
    document.dispatchEvent(new CustomEvent('garrison-go-back', {
      detail: newUrl,
    }));

    state = { step: 'root' };
    if (Globals.onPrem) {
      await handleAutomaticOnPremLogin();
    }
  }

  /**
   * This is run when the login page loads. There are different flows we can
   * take depending on several factors:
   *
   * - On prem / ULTRA?
   * - Presence of some query parameters (e.g. SAML response, license key)
   */
  onMount(() => {
    if (!Globals.onPrem) {
      // Check whether SSO is enabled in the background. This way we know whether
      // to display the "Continue with SSO" button on the root page.
      (async () => {
        const res = await Gcb.samlLogin(Globals.loginApiHost, {
          checkEnabledOnly: true,
        });

        // Once this boolean is set, the root login page will stop showing a
        // spinner and be rendered.
        //
        // If the API call fails for any reason, we presume SSO is not enabled.
        ssoEnabled = res.success === true;
      })();
    }

    // We just came back from the SSO flow
    if (query.response !== null) {
      if (query.response === 'success-provision') {
        handleSuccess();
      } else {
        document.dispatchEvent(new CustomEvent('garrison-login-error', { detail: document.location.href }));
        state = {
          step: 'error',
          error: formatLoginError(query.response),
        };
      }

      return;
    }

    // On prem should log in automatically. The reasoning is because there is no
    // access key log in for on-prem, so there is no choice for the user to
    // make.
    if (Globals.onPrem) {
      handleAutomaticOnPremLogin();
      return;
    }

    // ULTRA -->

    // If an access key is provided in the query parameters, go straight to the
    // access key page and provide an initial key, which will populate the text
    // field and automatically submit the form.
    if (query.k !== null) {
      state = {
        step: 'access-key',
        initialKey: query.k,
      };
    }
  });
</script>

<Page>
  <img class="bg" src={ASSET_image_bg} alt="bg" />
  <div class="container">

    {#if state.step === 'root'}
      {#if Globals.onPrem || ssoEnabled === undefined}
        <ModalSignInLoading />
      {:else}
        <ModalSignInRoot
          showSsoButton={ssoEnabled}
          disableButtons={disableButtons}
          on:continue-access-key={handleContinueAccessKey}
          on:continue-sso={handlePressRootContinueSso}
        />
      {/if}
    {:else if state.step === 'access-key'}
      <ModalSignInAccessKey
        initialKey={state.initialKey}
        inputError={state.error}
        on:go-back={() => { state.step = 'root'; }}
        on:sign-in={ev => handlePressAccessKeySignIn(ev.detail) }
      />
    {:else if state.step === 'sso'}
      <ModalSignInSso />
    {:else if state.step === 'success'}
      <ModalSignInSuccess />
    {:else if state.step === 'error'}
      <ModalSignInError
        error={state.error}
        on:go-back={handlePressErrorGoBack}
      />
    {/if}

    <PoweredByGarrison />
  </div>
</Page>

<style lang="scss">
  .bg {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
    z-index: -1;
  }

  .container {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    align-items: center;
  }
</style>
