import { ApiError } from '../../utility/ApiError';
import HttpRequest from '../../utility/HttpRequest';
import { makeRequest } from '../../utility/request';
import { getApiUrl } from '../api_stem';

type EndSessionResult =
{
  success: true
} |
{
  success: false,
  error: ApiError,
};

export async function endSession(
  host: string,
): Promise<EndSessionResult> {
  const resp = await makeRequest({
    request: new HttpRequest({
      url: getApiUrl(host, 'session'),
      method: 'DELETE',
    }),
    expectedResponseCodes: [200],
    jsonValidationSchema: undefined,
  });

  if (!resp.success) {
    return {
      success: false,
      error: resp.error,
    };
  }

  return { success: true };
}
