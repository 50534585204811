<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  
  import ASSET_close_button from 'assets/images/modal_close.svg';
  import CircleIcon from './circle_icon.svelte';
  import Spinner from './spinner.svelte';

  export let title: string = '';
  export let pending: boolean = false;
  export let image: string = '';
  export let type : 'danger' | 'information' | 'success' | 'warning' | 'modal-danger' | undefined = undefined;
  export let modalSize: 'lg' | 'md' | 'sm' = 'md';
  export let description: string | undefined = undefined;
  export let showCloseButton: boolean  = false;

  let contentClass: string;

  if (type === 'danger') {
    type = 'modal-danger';
  }

  $: {
    if (typeof $$restProps.contentClass === 'string' && $$restProps.contentClass.length > 0) {
      contentClass = $$restProps.contentClass;
    } else {
      contentClass = '';
    }
  }

  const dispatch = createEventDispatcher();

</script>

{#key pending}
  <form
    class="modal"
    class:modal-medium = {modalSize === 'md'}
    class:modal-large = {modalSize === 'lg'}
    class:modal-small = {modalSize === 'sm'}
    on:submit|preventDefault
  >
    {#if pending}
      <div class="content">
        <Spinner />
      </div>
    {:else}

      
      <div class="{contentClass} content"  class:extend-footer={showCloseButton === true}>

          <div class="header-container">
            <div class="title">
              {#if image}
              <CircleIcon icon={image} alt="user icon" colorType={type} />  
              {/if}
                     
              <div class="title-text">{title}</div>
              
            </div>

            {#if showCloseButton}
            <div class="button-container">
              <button
                class="close-button"
                on:click={() => dispatch('close-modal')}
              > 
                <img
                  src={ASSET_close_button}
                  alt="Close"
                  class="modal-close-img"
                />
              </button>
            </div>
            {/if}

          </div>
          
          {#if description} 
          <div class="description">{description}</div>          
          {/if}
          <slot class="{$$restProps.class}"/>
          
      </div>

      {#if $$slots.footer !== undefined}
        <div class="footer">
          <slot name="footer" class="{$$restProps.class}"/>
        </div>
      {/if}
    {/if}
  </form>
{/key}

<style lang="scss">
  @use "../scss/animations.scss";

.button-container {
  margin-top: -10px;
}

.close-button {  
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(15px, -5px);
  appearance: none;
  cursor: pointer;
  border: none;
  background-color: inherit;
  padding: 0px;
}

div.extend-footer {
  padding: 24px 24px 50px 24px;
}


.header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}


.description {
  color: var(--theme-col-text-5);
  font-size: 16px;  
  text-align: center;
  font-weight: 400;
}

  .modal {
    width: 550px;
    border-radius: 8px;
    overflow: hidden;
    max-height: 720px;
    border: 1px solid var(--theme-col-pale-aqua);
    margin: auto;
    background-color: var(--theme-col-white);
    position: relative;

    box-shadow:
      0 0 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);

    @include animations.slide-up;
  }

  .modal-medium {
    width: 550px;
  }

  .modal-large {
    width: 672px;
  }

  .modal-small {
    width: 300px;
  }

  .title {
    display: flex;
    flex-flow: column nowrap;   
    align-items: center;
    gap: 12px;
    width: 100%;

    .title-text {
      font-size: 20px;
      color: var(--theme-col-text-6);
      font-weight: 500;
      line-height: 20px; 
    }
  }

  .content {
    padding: 24px;
    font-family: var( --theme-font-display);    
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;
    align-items: center;
    text-align: center;
    overflow: hidden; 
  }

  .alignLeft {
    align-items: flex-start;
  }

  .footer {
    padding: 12px;
    background-color: var(--theme-col-bg-10);
    border-top: 1px solid var(--theme-col-pale-aqua);

    display: flex;
    flex-flow: row-reverse nowrap;
    gap: 12px;
  }
</style>
