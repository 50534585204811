import { z } from 'zod';
import { FileScanningIntegrationServiceType } from 'src/file_transfer/FileTransfer';
import Globals from '../Globals';
import { isObject, isStringArray } from '../utility/validate';
import { Appliance } from './Appliance';

export function filterApplianceList(
  applianceList: Appliance[],
  allowlist: string[],
): Appliance[] {
  if (allowlist.length === 0) {
    return applianceList;
  }

  const filteredApplianceList = applianceList.filter(appliance => {
    const fullAddress = `${appliance.ipAddress}:${appliance.protocolPort}`;
    const applianceAllowed = allowlist.find(a => fullAddress.startsWith(a)) !== undefined;
    return applianceAllowed;
  });

  return filteredApplianceList;
}

export type AppConfiguration = {
  transferAddresses: string[],
  pasteAllowed: boolean,
  pasteMaxCharacters: number,
  redirectAllowed: boolean,
  windowTitle: string,
  borderThickness: number,
  borderColor: string,
  inactivityTimeoutDefaultDurationSec: number,
  inactivityTimeoutExtensionDurationSec: number,
  debugLogs: boolean,
  fileTransferEnabled: boolean,
  fileTransferIntegrationService: FileScanningIntegrationServiceType | null
};

function validateBooleanString(value: any): boolean | null {
  if (typeof value !== 'string') {
    return null;
  }

  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return null;
}

function validateNumber(
  value: any,
  opts?: { min?: number,  max?: number },
): number | null {
  if (typeof value !== 'number') {
    return null;
  }

  if (
    opts?.min !== undefined &&
    value < opts.min
  ) {
    return null;
  }

  if (
    opts?.max !== undefined &&
    value > opts.max
  ) {
    return null;
  }

  return value;
}

function validateStringArray(
  value: any,
): string[] | null {
  if (!isStringArray(value)) {
    return null;
  }

  return value;
}

function validateString(value: any): string | null {
  const validation = z.string().safeParse(value);

  return validation.success ? value : null;
}

export function defaultAppConfiguration(): AppConfiguration {
  return {
    transferAddresses: [],
    redirectAllowed: false,
    pasteAllowed: false,
    pasteMaxCharacters: 4000,
    windowTitle: 'Garrison In-browser App',
    borderThickness: 0,
    borderColor: '#1CB254',
    inactivityTimeoutDefaultDurationSec: 3 * 60,
    inactivityTimeoutExtensionDurationSec: 30 * 60,
    debugLogs: Globals.onPrem,
    fileTransferEnabled: false,
    fileTransferIntegrationService: null
  };
}

export function parseAppConfigurationFromGcb(configString: string): AppConfiguration | null {
  let obj: any;

  try {
    obj = JSON.parse(configString);
  } catch {
    return null;
  }

  // 'null' is a valid response from the GCB, treat it the same as an empty
  // response
  if (obj === null) {
    obj = {};
  }

  if (!isObject(obj)) {
    return null;
  }

  // Default values
  const ret = defaultAppConfiguration();

  /* eslint-disable object-curly-newline */
  const CONFIGURATION_ENTRIES = [
    { name: 'transferAddresses'  , key: 'Transfer.IPs'     , type: 'string-array' },
    { name: 'redirectAllowed'    , key: 'Redirect.Allowed' , type: 'boolean'      },
    { name: 'pasteAllowed'       , key: 'Paste.Allowed'    , type: 'boolean'      },
    { name: 'pasteMaxCharacters' , key: 'Paste.Limit'      , type: 'number' , min: 1, max: 4000 },
    { name: 'windowTitle', key: 'Webapp.Window.Title', type: 'string' },
    { name: 'borderThickness', key: 'Webapp.Border.Thickness', type: 'number', min: 0, max: 50 },
    { name: 'borderColor', key: 'Webapp.Border.Colour', type: 'string' },
  ] as const;
  /* eslint-enable */

  for (let i = 0; i < CONFIGURATION_ENTRIES.length; ++i) {
    const entry = CONFIGURATION_ENTRIES[i];
    const rawValue = obj[entry.key];

    // If the key doesn't exist, we keep the default, but it's not an error
    if (rawValue === undefined) continue;

    // Otherwise validate the value, and if validation values then that's an
    // error and we return null.
    if (entry.type === 'boolean') {
      const value = validateBooleanString(rawValue);
      if (value === null) return null;
      ret[entry.name] = value;
    } else if (entry.type === 'string-array') {
      const value = validateStringArray(rawValue);
      if (value === null) return null;
      ret[entry.name] = value;
    } else if (entry.type === 'number') {
      const value = validateNumber(rawValue, { min: entry.min, max: entry.max });
      if (value === null) return null;
      ret[entry.name] = value;
    } else if (entry.type === 'string') {
      const value = validateString(rawValue);

      if (value === null) return null;

      ret[entry.name] = value;
    }
  }

  return ret;
}

export function goToPath(path: string) {
  const pathPrefix = (!BUILD_CONST_DEV_SERVER && Globals.onPrem) ? '/app/' : '/';

  const url = new URL(window.location.href);
  url.pathname = `${pathPrefix}${path}`;
  window.location.replace(url);
}
