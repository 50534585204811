<script lang="ts">
    import ASSET_image_logo from '../../../assets/images/garrison_logo.svg';
</script>

<div>
    <span>Powered by</span>
    <img src={ASSET_image_logo} alt="Garrison" />
</div>

<style lang="scss">
    div {
        display: flex;
        flex-flow: row nowrap;
        gap: 6px;
        align-items: center;
    }

    span {
        font-size: 12px;
        font-weight: 600;
    }

    img {
        width: 78px;
    }
</style>