import { getApiUrl } from '../api_stem';
import HttpRequest from '../../utility/HttpRequest';
import { makeRequest } from '../../utility/request';
import { ProvisioningApiError } from '../../utility/ProvisioningApiError';
import { ApiError } from '../../utility/ApiError';

type MessageOptions = {
  /**
   * Splash message ID (from /savi-nodes response)
   */
  messageId: string,
};

export type MessageResult =
{
  success: true,
} |
{
  success: false,
  error: ProvisioningApiError,
};

export async function message(host: string, opts: MessageOptions): Promise<MessageResult> {
  const req = new HttpRequest({
    url: getApiUrl(host, 'message'),
    method: 'POST',
    body: JSON.stringify({message_id: opts.messageId}),
  });

  const resp = await makeRequest({
    request: req,
    expectedResponseCodes: [200, 401, 403, 500],
  });

  if (!resp.success) {
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: resp.error,
      }),
    };
  }

  if (resp.response.body === undefined) {
    if (resp.response.statusCode === 200) {
      return {success: true};
    }
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: new ApiError({
          type: 'response-parse-json-error',
          detail: 'No response body',
          request: req,
          response: resp.response,
        }),
      }),
    };
  }

  // Handle error cases...

  let parsedResponse: any = {};
  try {
    parsedResponse = JSON.parse(resp.response.body);
  } catch (err) {
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: new ApiError({
          type: 'response-parse-json-error',
          detail: err.message,
          request: req,
          response: resp.response,
        }),
      }),
    };
  }

  switch (parsedResponse.response) {
    case 'invalid-credentials':
    case 'license-inactive':
    case 'license-expired':
    case 'missing-login-token':
    case 'internal-server-error':
    case 'forbidden':
      return {
        success: false,
        error: new ProvisioningApiError({ type: parsedResponse.response }),
      };
    default:
      return {
        success: false,
        error: new ProvisioningApiError({
          type: 'api-error',
          error: new ApiError({
            type: 'response-validate-json-error',
            detail: 'Failed to validate JSON response',
            request: req,
            response: resp.response,
          }),
        }),
      };
  }
}
