import { MessageFormatter } from 'svelte-i18n/types/runtime/types';
import { ApiError } from '../../utility/ApiError';
import HttpRequest from '../../utility/HttpRequest';
import { makeRequest } from '../../utility/request';
import { AppConfiguration, parseAppConfigurationFromGcb } from '../../../provisioning/utility';
import Error from '../../../utility/Error';
import { getApiUrl } from '../api_stem';

export type ConfigOpts = {
  systemKey: string,
};

type ConfigErrorDetail =
{
  type: 'api-error',
  error: ApiError,
} |
{
  type: 'config-parse-error',
};

export class ConfigError implements Error<ConfigErrorDetail> {
  constructor(
    readonly detail: ConfigErrorDetail,
  ) {}

  dump(): string {
    return JSON.stringify(this.detail);
  }

  toLocalizedString(formatter: MessageFormatter): string | null {
    return formatter('login_page.error.connecting_error_request_failed_configuration');
  }

  getDetailString(): string | null {
    switch (this.detail.type) {
      case 'api-error':
        return this.detail.error.getDetailString();
      case 'config-parse-error':
        return 'Failed to parse app configuration.';
    }

    return null;
  }
}

type AppConfigurationResult =
{
  success: true,
  configuration: AppConfiguration,
} |
{
  success: false,
  error: ConfigError,
};

export async function config(
  host: string,
  opts: ConfigOpts,
): Promise<AppConfigurationResult> {
  const resp = await makeRequest({
    request: new HttpRequest({
      url: getApiUrl(host, `config?system_key=${opts.systemKey}`),
      method: 'GET',
    }),
    expectedResponseCodes: [200],

    // Note: no JSON schema validation here - we are lenient with this APIs
    // response as it contains a lot of optional settings. (Many of which
    // might only apply to the desktop app)
    //
    // Instead roll a custom parse + validation function below
  });

  if (!resp.success) {
    return {
      success: false,
      error: new ConfigError({
        type: 'api-error',
        error: resp.error,
      }),
    };
  }

  if (resp.response.body === undefined) {
    return {
      success: false,
      error: new ConfigError({ type: 'config-parse-error' }),
    };
  }

  const appConfiguration = parseAppConfigurationFromGcb(resp.response.body);

  if (appConfiguration === null) {
    return {
      success: false,
      error: new ConfigError({ type: 'config-parse-error' }),
    };
  }

  return {
    success: true,
    configuration: appConfiguration,
  };
}
