import { ApiError } from '../../utility/ApiError';
import { makeRequest } from '../../utility/request';
import HttpRequest from '../../utility/HttpRequest';
import { getUltraFileTransferUrl } from '../api_stem';

export type Download = {
  filename: string,
  createdAt: number,
};

type ListDownloadsResult =
{
  success: true,
  downloads: Download[],
} |
{
  success: false,
  error: ApiError,
};

export async function listDownloads(
  host: string,
): Promise<ListDownloadsResult> {
  const schema = {
    definitions: {
      'download': {
        properties: {
          'filename': { type: 'string' },
          'created_at': { type: 'uint32' },
        },
      },
    },
    properties: {
      'downloads': { elements: { ref: 'download' } },
    },
  } as const;

  const resp = await makeRequest({
    request: new HttpRequest({
      url: getUltraFileTransferUrl(host, 'downloads'),
      method: 'GET',
    }),
    expectedResponseCodes: [200],
    jsonValidationSchema: schema,
  });

  if (!resp.success) {
    return {
      success: false,
      error: resp.error,
    };
  }

  const validatedResult = resp.value;

  const downloads = validatedResult.downloads.map(element => ({
    filename: element.filename, createdAt: element.created_at,
  }));

  downloads.sort((a: Download, b: Download) => {
    return b.createdAt - a.createdAt;
  });

  return {
    success: true,
    downloads,
  };
}
