<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte';
  import ASSET_image_user from 'assets/images/user.svg';
  import TextField from '../text_field.svelte';
  import Button from '../button.svelte';
  import { ExtrinsicPromise } from '../../../utility';
  import Modal from '../modal.svelte';

  const dispatch = createEventDispatcher<{
    'go-back': void,
    'sign-in': { accessKey: string, res: ExtrinsicPromise<void> },
  }>();

  export let inputError: string | undefined;
  export let initialKey: string | undefined;

  let inputValue: string = '';

  let pending = false;

  async function handleSubmit() {
    pending = true;

    const p = new ExtrinsicPromise<void>();
    dispatch('sign-in', { accessKey: inputValue, res: p });
    await p.promise;

    pending = false;
  }

  onMount(() => {
    if (initialKey !== undefined) {
      inputValue = initialKey;
      handleSubmit();
    }
  });
</script>

<Modal
  title="Sign in with an access key"
  on:submit={handleSubmit}
  image={ASSET_image_user}
  type="information"
>

  <TextField
  bind:value={inputValue}
  label="Access key"
  error={inputError}
  autoFocus
  disabled={pending}
/>

 

  <svelte:fragment slot="footer">
    <Button
      submit
      disabled={inputValue.length === 0}
      {pending}
    >
      Sign in
    </Button>

    <Button
      kind="secondary"
      on:press={() => { dispatch('go-back'); }}
      disabled={pending}
    >
      Go back
    </Button>
  </svelte:fragment>
</Modal>

<style>
  .wrapper {
    width:100%
  }
</style>
