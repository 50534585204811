import HttpRequest from '../../utility/HttpRequest';
import { makeRequest } from '../../utility/request';
import { getApiUrl } from '../api_stem';

type PostInteractionStatResult = { success: boolean };

type PostInteractionStatBody = { 'metric': string, 'count': number, 'timestamp': number }[];

export async function postUltraEvent(
  host: string,
  postBody: PostInteractionStatBody,
): Promise<PostInteractionStatResult> {
  const resp = await makeRequest({
    request: new HttpRequest({
      url: getApiUrl(host, 'statistics/events'),
      method: 'POST',
      body: JSON.stringify(postBody),
    }),
    expectedResponseCodes: [200],
  });

  return {
    success: resp.success,
  };
}
