const LOCAL_STORAGE_KEY = 'gwa:region';

// Ordered by priority - default region first
const ULTRA_REGION_VALUES = ['uk', 'us'] as const;

export type UltraRegion = typeof ULTRA_REGION_VALUES[number];

function isUltraRegion(value: string): value is UltraRegion {
  return ULTRA_REGION_VALUES.includes(value as UltraRegion);
}

export function getAllUltraRegions(): UltraRegion[] {
  return ULTRA_REGION_VALUES.slice();
}

export function getDefaultUltraRegion(): UltraRegion {
  return ULTRA_REGION_VALUES[0];
}

export function setUltraRegion(region: UltraRegion) {
  localStorage.setItem(LOCAL_STORAGE_KEY, region);
}

export function getUltraRegion(): UltraRegion | null {
  const rawRegionValue = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (rawRegionValue !== null && isUltraRegion(rawRegionValue)) {
    return rawRegionValue as UltraRegion;
  }
  return null;
}
