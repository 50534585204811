import Globals from '../../Globals';
import { UltraRegion, getDefaultUltraRegion, getUltraRegion } from '../../utility/ultra_region';

// Gets the (overridable) region path for ULTRA from localStorage
// Always returns empty string if we're on prem
function ultraRegionPathPart(region?: UltraRegion): string {
  const ultraRegion = region || getUltraRegion() || getDefaultUltraRegion();
  return Globals.onPrem ? '' : `/${ultraRegion}`;
}

export function getUltraFileTransferUrl(host: string, path: string) {
  return `https://${host}${ultraRegionPathPart()}/file-transfer/app/v1/${path}`;
}

export function getApiUrl(host: string, path: string, region?: UltraRegion) {
  const version = Globals.onPrem ? 3 : 4;
  return `https://${host}${ultraRegionPathPart(region)}/provisioning/app/v${version}/${path}`;
}

export function getTransferUrl(host: string, path: string) {
  const ultraPathPart = Globals.onPrem ? '' : '/ultra';
  return `https://${host}${ultraRegionPathPart()}${ultraPathPart}/transfer/${path}`;
}
