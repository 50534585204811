export function stripGarrisonProtocol(str: string): string {
  if (str.startsWith('garrison:')) {
    return str.replace(/^garrison:(\/\/)?/, '');
  }

  if (str.startsWith('garrisonext:')) {
    return str.replace(/^garrisonext:(\/\/)?(\w{3}:(\/\/)?)?/, '');
  }

  return str;
}

export function urlEncodeObject(obj: any) {
  let base64Encoded = btoa(encodeURIComponent(JSON.stringify(obj)));

  // make URL friendly
  base64Encoded = base64Encoded
    .replace(/\//g, '_')
    .replace(/\+/g, '-')
    .replace(/=+$/, '');

  return base64Encoded;
}

export function urlDecodeObject(base64: string): any | undefined {
  let ret;

  try {
    ret = JSON.parse(
      decodeURIComponent(
        atob(
          base64.replace(/_/g, '/').replace(/-/g, '+'),
        ),
      ),
    );
  } catch (err) {
    return undefined;
  }

  return ret;
}
