import { JTDDataType } from 'ajv/dist/jtd';
import { validateJson } from '../../utility/validate';
import { ApiError } from './ApiError';
import HttpRequest from './HttpRequest';
import HttpResponse from './HttpResponse';

type RequestOptions<JsonValidateSchemaType> = {
  request: HttpRequest,
  expectedResponseCodes?: number[],
  jsonValidationSchema?: JsonValidateSchemaType,
};

type MakeRequestResult<JsonValidateSchemaType> =
  {
    success: true,
    value: JTDDataType<JsonValidateSchemaType>,
    response: HttpResponse,
  } |
  {
    success: false,
    error: ApiError,
  };

export async function makeRequest<JsonValidateSchemaType>(
  opts: RequestOptions<JsonValidateSchemaType>,
): Promise<MakeRequestResult<JsonValidateSchemaType>> {
  let resp: HttpResponse;

  try {
    const credentials = opts.request.credentials ?? 'include';
    resp = await HttpResponse.fromFetchResponse(
      await fetch(
        opts.request.url,
        {
          method: opts.request.method,
          credentials,
          body: opts.request.body,
          headers: opts.request.headers,
        },
      ),
    );
  } catch (err) {
    return {
      success: false,
      error: new ApiError({
        type: 'network-error',
        request: opts.request,
        detail: err.message,
      }),
    };
  }

  if (
    opts.expectedResponseCodes !== undefined &&
    !opts.expectedResponseCodes.includes(resp.statusCode)
  ) {
    return {
      success: false,
      error: new ApiError({
        type: 'unexpected-response-code',
        expectedResponseCodes: opts.expectedResponseCodes,
        request: opts.request,
        response: resp,
      }),
    };
  }

  if (opts.jsonValidationSchema !== undefined) {
    let respJson: any;
    try {
      respJson = JSON.parse(resp.body ?? '');
    } catch (err) {
      return {
        success: false,
        error: new ApiError({
          type: 'response-parse-json-error',
          detail: err.message,
          request: opts.request,
          response: resp,
        }),
      };
    }

    const validateResult = validateJson(respJson, opts.jsonValidationSchema);

    if (!validateResult.success) {
      return {
        success: false,
        error: new ApiError({
          type: 'response-validate-json-error',
          detail: validateResult.error,
          request: opts.request,
          response: resp,
        }),
      };
    }

    return {
      success: true,
      value: validateResult.value,
      response: resp,
    };
  }

  return {
    success: true,

    // Can't figure out this one...
    // If no schema was specified the parsed validated value should be undefined.
    // This isn't too bad as TypeScript reports JTDDataDef<undefined> as unknown
    // anyway
    // @ts-ignore
    value: undefined,

    response: resp,
  };
}
