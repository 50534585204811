/**
 * A promise that can be resolved from the outside using a method call.
 */
export class ExtrinsicPromise<T> {
  private resolver: (value: T) => void = () => {};
  private rejecter: (reason?: any) => void = () => {};

  readonly promise: Promise<T> = new Promise<T>(
    (res, rej) => {
      this.resolver = res;
      this.rejecter = rej;
    }
  );

  private _resolved: boolean = false;
  get resolved() { return this._resolved; }

  resolve(value: T) {
    if (this._resolved) {
      return;
    }

    this._resolved = true;
    this.resolver(value);
  }

  reject(reason?: any) {
    if (this._resolved) {
      return;
    }

    this._resolved = true;
    this.rejecter(reason);
  }
}
