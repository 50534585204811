<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import ASSET_image_user from 'assets/images/user.svg';
  import Button from '../button.svelte';
  import Globals from '../../../Globals';
  import Modal from '../modal.svelte';

  const dispatch = createEventDispatcher<{
    'go-back': void,
  }>();

  export let error: string | undefined;

  const title = 'Failed to sign in to Garrison';

  const buttonText = Globals.onPrem
    ? 'Retry'
    : 'Go back';
</script>

<Modal {title} image={ASSET_image_user} type="information">
  {#if error}
    <p class="error">{error}</p>
  {/if}

  <svelte:fragment slot="footer">
      <Button
        kind="secondary"
        submit
        on:press={() => { dispatch('go-back'); }}
      >
        {buttonText}
      </Button>
  </svelte:fragment>
</Modal>

<style lang="scss">
  p.error {
    color: #dc2626;
  }
</style>
