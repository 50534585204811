import { ApiError } from '../../utility/ApiError';
import { makeRequest } from '../../utility/request';
import HttpRequest from '../../utility/HttpRequest';
import { getUltraFileTransferUrl } from '../api_stem';

type GetDownloadUrlResult =
  {
    success: true,
    url: string,
    delegated?: boolean,
  } |
  {
    success: false,
    type: 'scan-in-progress'
  } |
  {
    success: false,
    type: 'scanning-error',
    error: 'file-upload-failed' | 'malware-scan-failed' | 'generic-error' | 'scan-error' | 'azure-transient-error'
    message?: string
  } |
  {
    success: false,
    type: 'error',
    error?: ApiError,
  };

export async function getDownloadUrl(
  host: string,
  filename: string,
): Promise<GetDownloadUrlResult> {
  const req = new HttpRequest({
    url: getUltraFileTransferUrl(host, `downloads/${filename}/url`),
    method: 'GET',
  });

  const resp = await makeRequest({
    request: req,
    expectedResponseCodes: [200, 202, 404, 500],
  });

  if (!resp.success) {
    return {
      success: false,
      type: 'error',
      error: resp.error,
    };
  }

  // Success case...

  // We need to parse 202 as if it's an error
  // to make web app call the endpoint again
  if (resp.response.statusCode === 202) {
    return { success: false, type: 'scan-in-progress' };
  }

  const body = resp.response.body;
  let parsedResponse: any = {};

  // Error case...

  // If body not provided, fall back to a generic error message
  if (body === undefined || body === '') { return { success: false, type: 'scanning-error', error: 'generic-error' }; }

  try {
    parsedResponse = JSON.parse(body);

    return {
      success: true,
      url: parsedResponse.url,
      delegated: parsedResponse.delegated,
    };
  } catch (err) {
    if (resp.response.statusCode === 500) {
      switch (body.toLocaleLowerCase()) {
        case 'file upload failed':
          return { success: false, type: 'scanning-error', error: 'file-upload-failed' };
        case 'malware scan failed':
          return { success: false, type: 'scanning-error', error: 'malware-scan-failed' };
        case 'internal server error':
          return { success: false, type: 'error' };
        // we reupload the file on Azure errors that indicate the scan should succeed on retry,
        // so all of these need to be handled separately
        case 'scan failed - internal service error': case 'scan timed out - the requested scan exceeded time limitation': case 'scan was throttled by the service':
          return {
            success: false, type: 'scanning-error', error: 'azure-transient-error', message: body
          };
        default:
          return {
            success: false, type: 'scanning-error', error: 'scan-error', message: body
          };
      }
    }

    return {
      success: false,
      type: 'error',
      error: new ApiError({
        type: 'response-validate-json-error',
        detail: err.message,
        request: req,
        response: resp.response,
      })
    };
  }
}
