<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import Spinner from './spinner.svelte';

  export let kind: 'primary' | 'secondary' = 'primary';
  export let pending: boolean = false;
  export let disabled: boolean = false;
  export let submit: boolean = false;

  let buttonType: 'submit' | 'button';
  $: buttonType = submit ? 'submit' : 'button';

  const dispatch = createEventDispatcher<{
    'press': void,
  }>();
</script>

<button
    type={buttonType}
    class:primary={kind === 'primary'}
    class:secondary={kind === 'secondary'}
    class:pending
    disabled={disabled || pending}
    on:click={() => { dispatch('press'); }}
>
  {#if pending}
    <Spinner />
  {/if}
  <div class="content">
    <slot />
  </div>
</button>

<style lang="scss">
    button {
      flex: 1 0 auto;
      height: 34px;
      border-radius: 6px;
      padding: 7px 16px;
      text-align: center;
      border: 0px;

      font-family: "Graphie";
      font-size: 16px;
      font-weight: 500;

      transition-property: background-color, opacity;
      transition-duration: 180ms;

      $shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      box-shadow: $shadow;

      user-select: none;

      min-width: 86px;
  

      --spinner-color: white;

      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto;
      place-items: center;

      &.pending > .content {
        opacity: 0;
        pointer-events: none;
      }

      & > :global(*) {
        grid-row: 1/2;
        grid-column: 1/2;
      }

      &:focus-visible {
        outline: 2px solid #0693e3;
      }

      &:not(:disabled) {
        cursor: pointer;
      }

      &:disabled {
        opacity: 0.5;
      }

      &.primary {
        background-color: #0693e3;
        color: white;

        &:not(:disabled) {
          &:hover {
            background-color: #0587d3;
          }

          &:active {
            background-color: #0079be;
          }

          &:focus-visible {
            outline-color: #0079be;
          }
        }
      }

      &.secondary {
        background-color: white;
        color: var(--theme-col-text-6);

        box-shadow: $shadow, 0 0 0 1px inset #bed1ce;

        &:not(:disabled) {
          &:hover {
            background-color: #f5f5f5;
          }

          &:active {
            background-color: #e9e9e9;
          }
        }
      }
    }
</style>
