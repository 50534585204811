import { parseCookies } from './utility/cookies';

const COOKIES = parseCookies();

export default {
  onPrem: COOKIES['GARRISON_WEBAPP_ONPREM'] === 'true',

  loginApiHost: COOKIES['login-api-host'] ?? window.location.host,
  provisioningApiHost: COOKIES['provisioning-api-host'] ?? window.location.host,
  transferApiHost: COOKIES['transfer-api-host'] ?? window.location.host,
  fileTransferApiHost: COOKIES['file-transfer-api-host'] ?? window.location.host,
};
