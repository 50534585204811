<script lang="ts">
  export let size: 'small' | 'large' = 'small';
</script>

<div
  class:small={size === 'small'}
  class:large={size === 'large'}
>
</div>

<style lang="scss">
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  div {
    border-style: solid;
    border-color: var(--spinner-color, #0693e3);
    border-left-color: transparent;

    &.small {
      border-width: 2px;
      width: 16px;
      height: 16px;
      border-radius: 8px;
    }

    &.large {
      border-width: 4px;
      width: 32px;
      height: 32px;
      border-radius: 16px;
    }

    animation-name: rotate;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }
</style>
