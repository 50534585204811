import * as Uuid from 'uuid';

const USER_ID_LOCAL_STORAGE_KEY = 'gwa:user-id';

function validUserId(userId: string): boolean {
  if (!Uuid.validate(userId)) {
    return false;
  }

  if (Uuid.version(userId) !== 4) {
    return false;
  }

  return true;
}

export default class Profile {
  readonly userId: string;

  constructor(opts?: { userId?: string }) {
    const optsUserId = opts?.userId ?? '';
    const localStorageUserId = localStorage.getItem(USER_ID_LOCAL_STORAGE_KEY) ?? '';

    if (validUserId(optsUserId)) {
      this.userId = optsUserId;
    } else if (validUserId(localStorageUserId)) {
      this.userId = localStorageUserId;
    } else {
      this.userId = Uuid.v4();
      localStorage.setItem(USER_ID_LOCAL_STORAGE_KEY, this.userId);
    }
  }
}
