import { byteSwap16 } from '../../../utility/bitwise';
import { getApiUrl } from '../api_stem';
import HttpRequest from '../../utility/HttpRequest';
import { makeRequest } from '../../utility/request';
import { Appliance } from '../../../provisioning/Appliance';
import { ProvisioningApiError } from '../../utility/ProvisioningApiError';
import { ApiError } from '../../utility/ApiError';
import Globals from '../../../Globals';

type ApplianceGroup = {
  appliances: Appliance[],
};

export type ApplianceGroups = ApplianceGroup[];

type ListAppliancesOptions = {
  onPremAllowlistEnabled: boolean,
};

const defaultListAppliancesOptions: ListAppliancesOptions = {
  'onPremAllowlistEnabled': false
};

type ListAppliancesResult =
{
  success: true,
  applianceGroups: ApplianceGroups,
} |
{
  success: false,
  error: ProvisioningApiError,
};

export async function appliances(host: string, opts: ListAppliancesOptions = defaultListAppliancesOptions): Promise<ListAppliancesResult> {
  let url: string = getApiUrl(host, 'appliances');
  if (opts.onPremAllowlistEnabled && Globals.onPrem) {
    url += '?show_all_available=true';
  }
  const req = new HttpRequest({
    url,
    method: 'GET',
  });

  const resp = await makeRequest({
    request: req,
    expectedResponseCodes: [200, 401, 403, 500],
  });

  if (!resp.success) {
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: resp.error,
      }),
    };
  }

  if (resp.response.body === undefined) {
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: new ApiError({
          type: 'response-parse-json-error',
          detail: 'No response body',
          request: req,
          response: resp.response,
        }),
      }),
    };
  }

  let parsedResponse: any = {};
  try {
    parsedResponse = JSON.parse(resp.response.body);
  } catch (err) {
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: new ApiError({
          type: 'response-parse-json-error',
          detail: err.message,
          request: req,
          response: resp.response,
        }),
      }),
    };
  }

  switch (parsedResponse.response) {
    case undefined:
      try {
        return {
          success: true,
          applianceGroups: parsedResponse.appliances.map((applianceGroup: any[]) => ({
            appliances: applianceGroup.map(appliance => ({
              systemKey: appliance.system_key,
              ipAddress: appliance.ip_address,
              // Appliance reports ports byte-swapped for some reason
              protocolPort: byteSwap16(appliance.protocol_port),
              pingPort: byteSwap16(appliance.ping_port),
            })),
          })),
        };
      } catch (err) {
        return {
          success: false,
          error: new ProvisioningApiError({
            type: 'api-error',
            error: new ApiError({
              type: 'response-validate-json-error',
              detail: err.message,
              request: req,
              response: resp.response,
            }),
          }),
        };
      }
    case 'invalid-credentials':
    case 'license-inactive':
    case 'license-expired':
    case 'missing-login-token':
    case 'internal-server-error':
    case 'forbidden':
      return {
        success: false,
        error: new ProvisioningApiError({ type: parsedResponse.response }),
      };
    default:
      return {
        success: false,
        error: new ProvisioningApiError({
          type: 'api-error',
          error: new ApiError({
            type: 'response-validate-json-error',
            detail: 'Failed to validate JSON response',
            request: req,
            response: resp.response,
          }),
        }),
      };
  }
}
