import { ProvisioningApiError } from '../../utility/ProvisioningApiError';
import HttpRequest from '../../utility/HttpRequest';
import { makeRequest } from '../../utility/request';
import { getApiUrl } from '../api_stem';
import { ApiError } from '../../utility/ApiError';

export type OnPremLoginApiOptions =
  {
    userId: string,
  };

export const ONPREM_LOGIN_RESPONSES_SUCCESS = [
  'success-provision',
] as const;

export type OnPremLoginResponseSuccess =
  typeof ONPREM_LOGIN_RESPONSES_SUCCESS[number];

export type OnPremLoginResult =
  {
    success: true,
    type: 'provision',
  } |
  {
    success: true,
    type: 'redirect',
    url: string,
  } |
  {
    success: false,
    error: ProvisioningApiError,
  };

export async function onPremLogin(host: string, opts: OnPremLoginApiOptions): Promise<OnPremLoginResult> {
  const postBodyObj: {
    user: string,
  } = {user: opts.userId};

  let postBody: string | undefined = JSON.stringify(postBodyObj);
  if (postBody === '{}') { postBody = undefined; }

  const req = new HttpRequest({
    url: getApiUrl(host, 'login'),
    method: 'POST',
    body: postBody,
  });

  const resp = await makeRequest({
    request: req,
    expectedResponseCodes: [200, 302, 400, 401, 403, 500],
  });

  if (!resp.success) {
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: resp.error,
      }),
    };
  }

  if (resp.response.body === undefined) {
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: new ApiError({
          type: 'response-parse-json-error',
          detail: 'No response body',
          request: req,
          response: resp.response,
        }),
      }),
    };
  }

  let parsedResponse: any = {};
  try {
    parsedResponse = JSON.parse(resp.response.body);
  } catch (err) {
    return {
      success: false,
      error: new ProvisioningApiError({
        type: 'api-error',
        error: new ApiError({
          type: 'response-parse-json-error',
          detail: err.message,
          request: req,
          response: resp.response,
        }),
      }),
    };
  }

  // Not all of these are maybe valid in the onprem case
  switch (parsedResponse.response) {
    case 'success-redirect':
      return { success: true, type: 'redirect', url: parsedResponse.url };
    case 'success-provision':
      return { success: true, type: 'provision' };
    case 'bad-request':
    case 'invalid-credentials':
    case 'saml-auth-failed':
    case 'license-inactive':
    case 'license-expired':
    case 'user-deactivated':
    case 'unknown-users-disabled':
    case 'active-sessions-limit-reached':
    case 'internal-server-error':
      return {
        success: false,
        error: new ProvisioningApiError({ type: parsedResponse.response }),
      };
    default:
      return {
        success: false,
        error: new ProvisioningApiError({
          type: 'api-error',
          error: new ApiError({
            type: 'response-validate-json-error',
            detail: 'Failed to validate JSON response',
            request: req,
            response: resp.response,
          }),
        }),
      };
  }
}
